.group-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.works {
    color: #c5c5c5;
    text-align: left;
    font-family: "HankenGrotesk-Bold", sans-serif;
    font-size: 40px;
    font-weight: 700;

  }
  .line-4 {
    border-width: 3px 0 0 0;
    border-style: solid;
    border-image: linear-gradient(
      90deg,
      rgba(0, 128, 128, 0) 0%,
      rgba(0, 128, 128, 1) 50.999999046325684%,
      rgba(0, 128, 128, 0) 100%
    );
    border-image-slice: 1;
    width: 202px;
    height: 0px;
  }
  