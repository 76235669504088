.service1 {
    display: flex;
    justify-content: center;
    padding: 50px;
}
.firstservice {
  border-radius: 20px;
  border-style: solid;
  border-color: #008080;
  border-width: 2px;
  width: 50%;
  height: 493px;
  box-shadow: 0px 0px 40.5px 10px rgba(0, 128, 128, 0.5);
  display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  padding: 10px;
}


.picture {
  border-radius: 16.62px 5.54px 5.54px 16.62px;
  border-style: solid;
  border-color: #c5c5c5;
  border-width: 0.49px;
  width: 50%;
  height: 100%;
  box-shadow: 0px 0px 20px 3px rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: right;
  align-items: flex-end;
  padding-bottom: 20px ;
  padding-left: 10px;
  background-repeat: no-repeat;
}

.ServiceContent{
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
}

.blur {
  background: rgba(232, 232, 219, 0.3);
  border-radius: 11.08px 0px 0px 11.08px;
  width: 308.22px;
  height: 153.86px;
  backdrop-filter: blur(7.5px);
  display : flex;
    justify-content: left;
    align-items: center;
}
.forecasting {
  color: #008080;
  text-align: left;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 30px;
  font-weight: 700;
}
.wisevision_solution1 {
  color: #ffffff;
  text-align: justify;
  font-family: "Hanken Grotesk-Light", sans-serif;
  font-size: 20px;
  font-weight: 300;
  width: 95%;
}

@media screen and (max-width: 1100px) { 

  .wisevision_solution1 {
    font-size: 16px ;
  }
  .forecasting {
    font-size: 22px;
  }

}

@media screen and (max-width: 800px) {
  .firstservice {
      width: 100%;
      height: 400px;
  }
  .wisevision_solution1 {
    font-size: 16px ;
  }
}

@media screen and (max-width: 500px) { 

  .wisevision_solution1 {
    font-size: 12px ;
  }
  .forecasting {
    font-size: 20px;
  }
  .firstservice {
    width: 100%;
    height: 300px;
}
  
}

@media screen and (max-width: 400px) { 
  .forecasting {
    font-size: 16px;
  }
  .wisevision_solution1 {
    font-size: 10px ;
  }

}