.contif {
    background: #040C18;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0% 8%;
    gap: 6%;
}

.pargraphe{
    color: #fff;
    display: flex;
    text-align: justify;
    

}

.frame45{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0% 8%;
    gap: 10px;
}

.logo{
    width:100% ;
}

.illustarion{
    display: flex;
    justify-content: center ;
    width: 50%;
}


@media screen and (max-width: 700px) {
    .illustarion{
        display: none;
    }

    .contif {
        justify-content: center;
        align-items: center;
    }

    .frame45{
        width: 80%;
        padding-top: 10%;
    }
}